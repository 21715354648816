import { useState, useEffect, ReactElement, ReactNode } from "react";
import { clsx } from "clsx";
import { cn } from "@/lib/utils";

import { X as CloseIcon } from "lucide-react";

type classNameTypes = {
  right: string;
  left: string;
  top: string;
  bottom: string;
};

type drawerClassNameType = {
  open: classNameTypes;
  close: classNameTypes;
  main: classNameTypes;
};

interface DrawerProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  backgroundColor?: string;
  width?: string;
  padding?: string;
  margin?: string;
  side: string;
  hiddenCloseIcon?: boolean;
  colorCloseIcon?: string;
  buttonContent: ReactElement | null;
  children: ReactNode;
}

export default function FireDrawer({
  isOpen,
  onOpenChange,
  backgroundColor = "white",
  width = "lg:w-auto",
  padding = "p-5",
  margin,
  side = "right",
  colorCloseIcon = "black",
  hiddenCloseIcon = false,
  buttonContent,
  children,
}: DrawerProps) {
  const [localOpen, setLocalOpen] = useState(isOpen ?? false);
  const open = isOpen !== undefined ? isOpen : localOpen;

  const handleOpenChange = (open: boolean) => {
    if (onOpenChange) {
      onOpenChange(open);
    } else {
      setLocalOpen(open);
    }
  };

  useEffect(() => {
    // Drawer açıldığında body'yi kaydırmadan kapatma
    document.body.style.overflow = open ? "hidden" : "auto";

    // Cleanup: Drawer kapatıldığında overflow'ü eski haline döndürme
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const drawerClassNames: drawerClassNameType = {
    open: {
      right: "translate-x-0",
      left: "translate-x-0",
      top: "translate-y-0",
      bottom: "translate-y-0",
    },
    close: {
      right: "translate-x-full",
      left: "-translate-x-full",
      top: "-translate-y-full",
      bottom: "translate-y-full",
    },
    main: {
      right: "inset-y-0 right-0",
      left: "inset-y-0 left-0",
      top: "inset-x-0 top-0",
      bottom: "inset-x-0 bottom-0",
    },
  };

  const findValue = (object: object) => {
    return Object.entries(object).find(([key, val]) => key === side)?.[1];
  };

  return (
    <>
      <div onClick={() => handleOpenChange(!open)}>{buttonContent}</div>
      <div
        id={`dialog-${side}`}
        className={cn(
          "relative z-10",
          open
            ? "visible opacity-100 duration-500 ease-in-out"
            : "invisible opacity-0 duration-500 ease-in-out"
        )}
        aria-labelledby="slide-over"
        role="dialog"
        aria-modal="true"
        onClick={() => handleOpenChange(!open)}>
        <div
          className={clsx(
            "fixed inset-0 bg-black bg-opacity-80 transition-all",
            {
              "visible opacity-100 duration-500 ease-in-out": open,
            },
            { "invisible opacity-0 duration-500 ease-in-out": !open }
          )}></div>
        <div className={clsx({ "fixed inset-0 overflow-hidden": open })}>
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={clsx(
                "pointer-events-none fixed max-w-full",
                findValue(drawerClassNames.main)
              )}>
              <div
                className={clsx(
                  "pointer-events-auto relative h-full w-full transform transition duration-500 ease-in-out",
                  { [findValue(drawerClassNames.close)]: !open },
                  { [findValue(drawerClassNames.open)]: open }
                )}
                onClick={(event) => {
                  const targetElement = event.target as HTMLElement;

                  if (
                    !(
                      targetElement.tagName.toLowerCase() === "a" &&
                      targetElement.hasAttribute("href")
                    )
                  ) {
                    event.preventDefault();
                    event.stopPropagation();
                  }
                }}>
                <div
                  className={` ${side == "right" || side == "left" ? "w-[92vw]" : "w-full"} ${width} flex h-full flex-col bg-${backgroundColor} ${padding} shadow-xl`}>
                  {!hiddenCloseIcon && (
                    <button
                      className="absolute right-2 top-2"
                      onClick={() => handleOpenChange(false)}>
                      <CloseIcon
                        size={31}
                        className={`text-${colorCloseIcon}`}
                      />
                    </button>
                  )}
                  <div className={`${margin}`}>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
